import Vue  from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        cartCount: 0,
        wishlistProducts: [],
        showModal: false,
        dialogData: {
            title: '',
            text: '',
            cancelBtn: {
                label: ''
            },
            actionBtn: {
                label: '',
                actionUrl: ''
            }
        },
        modalStaticData: {
            title: '',
            content: ''
        },
        productConfigModalData: {
            configType: '',
            title: '',
            listData: []
        },
        productConfigChoice: null
    },
    
    actions: {
        async updateCartCount({ commit }) {
            axios({
                url: '/actions/commerce-utilities-module/cart/get-cart-count',
                method: 'GET'
            }).then(result => {
                if (result.status === 200) {
                    // Update cart count to show in navigation
                    commit("UPDATE_CART_COUNT", result.data);
                } else {
                    console.error('Could not get cart count.', result);
                }
            }).catch(error => {
                console.error('Could not get cart count.', error);
            });
        },
        
        loadWishlist({ commit }) {
            if (localStorage.getItem('wishlistProducts')) {
                try {
                    const ids = JSON.parse(localStorage.getItem('wishlistProducts'));
                    commit("LOAD_WISHLIST", ids);
                } catch (e) {
                    localStorage.removeItem('wishlistProducts');
                }
            }
        },
        
        addToWishlist({ commit }, id) {
            commit("ADD_TO_WISHLIST", id.toString());
        },
        
        toggleModal({ commit }, componentName) {
            commit("TOGGLE_MODAL", componentName);
        },
    
        setDialogData({ commit }, payload) {
            commit("SET_DIALOG_DATA", payload);
        },
    
        setModalStaticData({ commit }, payload) {
            commit("SET_MODAL_STATIC_DATA", payload);
        },
        
        setProductConfiguratorData({ commit }, payload) {
            commit("SET_PRODUCT_CONFIG_MODAL_DATA", payload);
        },
        
        setProductConfiguratorChoice({ commit }, payload) {
            commit("SET_PRODUCT_CONFIG_CHOICE", payload);
        }
    },
    
    mutations: {
        UPDATE_CART_COUNT(state, count) {
            state.cartCount = count;
        },
        
        LOAD_WISHLIST(state, ids) {
            state.wishlistProducts = ids;
        },
        
        ADD_TO_WISHLIST(state, id) {
            if (state.wishlistProducts.includes(id)) {
                state.wishlistProducts.splice(state.wishlistProducts.indexOf(id), 1);
            } else {
                state.wishlistProducts.push(id);
            }
            
            const parsed = JSON.stringify(state.wishlistProducts);
            localStorage.setItem('wishlistProducts', parsed);
        },
        
        TOGGLE_MODAL(state, componentName) {
            state.showModal = componentName || false;
        },
    
        SET_DIALOG_DATA(state, payload) {
            state.dialogData = payload;
        },
    
        SET_MODAL_STATIC_DATA(state, payload) {
            state.modalStaticData = payload;
        },
        
        SET_PRODUCT_CONFIG_MODAL_DATA(state, payload) {
            state.productConfigModalData = payload;
        },
        
        SET_PRODUCT_CONFIG_CHOICE(state, payload) {
            state.productConfigChoice = payload;
        }
    },
    
    getters: {
        cartCount(state) {
            return state.cartCount;
        },
        
        getWishlist(state) {
            return state.wishlistProducts;
        },
        
        getWishlistCount(state) {
            return state.wishlistProducts.length;
        },
        
        isOnWishlist: state => id => {
            return state.wishlistProducts.includes(id.toString());
        },
        
        showModal(state) {
            return state.showModal;
        },
        
        getDialogData(state) {
            return state.dialogData;
        },
        
        getModalStaticData(state) {
            return state.modalStaticData;
        },
        
        getProductConfigModalData(state) {
            return state.productConfigModalData;
        },
        
        getProductConfiguratorChoice(state) {
            return state.productConfigChoice;
        }
    }
});
