const translations = {
    en: {
        emptyWishlist: 'We couldn"t find any products on your wishlist.',
        loadingProducts: "Loading products...",
        loadingFilters: "Loading filters...",
        emptyProducts: "No products found",
        loading: "loading...",
        uploadWarranty: "Upload your proof of warranty",
        selectOption: "Select an option...",
        filesSelected: "{count} files selected",
        "new": "new",
        "instock": "In stock",
        "filters": "filters",
        "Prijs": "Price",
        "Minimum prijs": "Minimum price",
        "Maximum prijs": "Maximum price",
        "tot": "till",
        "toepassen": "apply",
        "wissen": "reset",
        "sluiten": "close",
        "vanaf": "from",
        "Verwijder": "Remove",
        "Kiezen": "Choose",
        soldPerPiece: "<strong>Sold per piece!</strong>strong>",
        loadMoreProducts: "more products",
        "Toevoegen": "Add",
        "Aantal": "Quantity",
        "geschenkbon": "gift card",
        "digitaal": "digital",
        "Prijs filter toepassen": "Apply pricing filter",
        "Bekijk": "View",
        
        validation: {
            required: "This field is required",
            general: "Fill in all required field, please.",
            productConfiguration: "One or more of your configuration inputs are invalid, please review your inputs.",
            email: "E-mail is not valid",
            imagExtensions: "The allowed file extensions are: JPG, JPEG, BMP, PNG and PDF.",
            imageSize: "The file must not be larger than 5MB",
        },

        error: {

        },
        
        product: {
            selectDefault: "Make your choice...",
            addToCart: "Add to cart",
            unavailable: "Unavailable",
            customLettersLabel: "Your characters choice for word {word}",
            customEngravingSidesLabel: "Text to engrave on side {side}",
            characterRestrictionWarning: 'Character choice is limited to A-Z a-z 0-9 èéêëàáâ .,/-_"&()?!',
            templateDownloadText: '<a href="{url}" target="_blank">Download this template</a> to create your drawing and send it to <a href="mailto:info@elisalee.be">info@elisalee.be</a>',
            apiConnectionError: "We could not connect to the API. Please try again later or contact us.",
            addToCartError: "Sorry, we could not add this product to your cart.",
            removeFromCartError: "Sorry, we could not remove this product from your cart.",
            generalError: "There was an error.",
            wishlistError: "We could not retrieve your favorite products.",
            noProductForConf: "No product found with this configuration.",
            buyPrototype: "I buy a prototype",
            addedToCart: "The product was added to your basket.",
            customDrawing: "Free writing or drawing",
            "karakter(s) resterend": "character(s) remaining",
            "Bedankt": "Thanks",
            "Verder winkelen": "Continue shopping",
            "Naar winkelmandje": "View your cart",
            "product": "product",
            "producten": "products",
            "engravingSidesWarning": "Don't forget to choose the number of engraving sides!",
            tryAgain: "Please try again."
        }
    },
    nl: {
        emptyWishlist: "We hebben geen producten gevonden op jouw wenslijst.",
        loadingProducts: "Producten laden...",
        loadingFilters: "Filters laden...",
        emptyProducts: "Geen producten gevonden",
        loading: "laden...",
        uploadWarranty: "Upload uw garantiebewijs",
        selectOption: "Optie selecteren...",
        filesSelected: "{count} bestanden geselecteerd",
        "new": "new",
        "instock": "Op stock",
        "filters": "filters",
        "Prijs": "Prijs",
        "Minimum prijs": "Minimum prijs",
        "Maximum prijs": "Maximum prijs",
        "tot": "tot",
        "toepassen": "toepassen",
        "wissen": "wissen",
        "sluiten": "sluiten",
        "vanaf": "vanaf",
        "Verwijder": "Verwijder",
        "Kiezen": "Kiezen",
        soldPerPiece: "<strong>Wordt per stuk verkocht!</strong>strong>",
        loadMoreProducts: "meer producten",
        "Toevoegen": "Toevoegen",
        "Aantal": "Aantal",
        "geschenkbon": "geschenkbon",
        "digitaal": "digitaal",
        "Prijs filter toepassen": "Prijs filter toepassen",
        "Bekijk": "Bekijk",
        
        validation: {
            required: "Dit veld is verplicht",
            general: "Vul alle verplichte velden in, aub.",
            productConfiguration: "Een of meerdere van uw configuratie-opties zijn ongeldig. Controleer uw ingevulde data, aub.",
            email: "Ongeldig e-mail adres",
            imagExtensions: "De toegelaten bestandsextensies zijn: JPG, JPEG, BMP, PNG en PDF.",
            imageSize: "Het bestand mag niet groter zijn of 5MB",
        },
        
        product: {
            selectDefault: "Maak uw keuze...",
            addToCart: "In winkelmand",
            unavailable: "Niet beschikbaar",
            customLettersLabel: "Typ hier uw letters voor woord {word}",
            customEngravingSidesLabel: "De te graveren tekst voor zijde {side}",
            characterRestrictionWarning: 'Character keuze is beperkt tot A-Z a-z 0-9 èéêëàáâ .,/-_"&()?!',
            templateDownloadText: '<a href="{url}" target="_blank">Download dit sjabloon</a> om uw tekening te maken en stuur deze door naar <a href="mailto:info@elisalee.be">info@elisalee.be</a>',
            apiConnectionError: "We konden de API niet bereiken. Probeer later opnieuw of contacteer ons.",
            addToCartError: "Sorry, we konden dit product niet toevoegen aan jouw mandje.",
            removeFromCartError: "Sorry, we konden dit product niet verwijderen uit jouw mandje.",
            generalError: "Er is een fout opgetreden.",
            wishlistError: "We konden jouw favoriete producten niet ophalen.",
            noProductForConf: "Geen product gevonden met deze configuratie.",
            buyPrototype: "Ik koop een prototype",
            addedToCart: "Het product werd toegevoegd aan jouw mandje.",
            customDrawing: "Vrij schrijfsel of tekening",
            "karakter(s) resterend": "karakter(s) resterend",
            "Bedankt": "Bedankt",
            "Verder winkelen": "Verder winkelen",
            "Naar winkelmandje": "Naar winkelmandje",
            "product": "product",
            "producten": "producten",
            "engravingSidesWarning": "Vergeet het aantal gravurezijdes niet in te geven!",
            tryAgain: "Probeer aub opnieuw."
        }
    }
}

export default translations;
