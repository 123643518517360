// Polyfills
import "promise-polyfill/src/polyfill";
//import 'loading-attribute-polyfill';

// Imports
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Swiper, { Pagination, EffectFade } from "swiper";
import store from "./store.js";
import { mapActions, mapGetters } from "vuex";
import lozad from "lozad";
import Notifications from "vue-notification";
import VueI18n from "vue-i18n";
import translations from "../js/translations";
import AOS from "aos";

import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm.js";

// Import our CSS
import "../css/app-base.pcss";
import "../css/app-components.pcss";
import "../css/app-utilities.pcss";

// Lozad
window.lozadObserver = lozad(".lozad", {
    loaded: (el) => {
        const cropPos = el.getAttribute("data-crop-pos");
        if (cropPos) {
            const img = el.getElementsByTagName("img");
            if (img) img[0].style.objectPosition = cropPos;
        }
        el.classList.add("loaded");
    },
});

// Install swiper modules to use
Swiper.use([Pagination, EffectFade]);

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue"); // prettier-ignore
    const { default: axios } = await import(/* webpackChunkName: "axios" */ "axios"); // prettier-ignore
    const VueCookies = await import(/* webpackChunkName: "vue-cookies" */ "vue-cookies"); // prettier-ignore
    const vClickOutside = await import(/* webpackChunkName: "v-click-outside" */ "v-click-outside"); // prettier-ignore
    
    Vue.use(VueCookies);
    Vue.use(Notifications);
    Vue.use(VueI18n);
    
    Vue.filter("pretty", function (value) {
        return JSON.stringify(value, null, "\t");
    });
    
    const i18n = new VueI18n({
        messages: translations,
    });
    
    window.axios = axios;
    window.i18n = i18n;
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ["${", "}"],
        store,
        i18n,
        
        directives: {
            clickOutside: vClickOutside.directive,
        },
        
        components: {
            "product-configurator": () => import(/* webpackChunkName: "product-configurator" */ "../vue/ProductConfigurator.vue"), // prettier-ignore
            "modal-container": () => import(/* webpackChunkName: "modal-container" */ "../vue/ModalContainer.vue"), // prettier-ignore
            "wish-list": () => import(/* webpackChunkName: "wish-list" */ "../vue/WishList.vue"), // prettier-ignore
            "frequently-asked-questions": () => import(/* webpackChunkName: "frequently-asked-questions" */ "../vue/FrequentlyAskedQuestions.vue"), // prettier-ignore
            "products-grid": () => import(/* webpackChunkName: "products-grid" */ "../vue/ProductsGrid.vue"), // prettier-ignore
            "add-product": () => import(/* webpackChunkName: "add-product" */ "../vue/AddProduct.vue"), // prettier-ignore
            "jewelry-clinic": () => import(/* webpackChunkName: "jewelry-clinic" */ "../vue/JewelryClinic.vue"),// prettier-ignore
            "gift-card": () => import(/* webpackChunkName: "gift-card" */ "../vue/GiftCard.vue") // prettier-ignore
        },
        
        data: {
            isBreakpointNM: false,
            isTouchDevice: false,
            
            // Cookie consent
            showNotificationBar: true,
            
            // Navigation
            lastScrollPos: 0,
            mobileNavOffset: 0,
            mobileNavIsOpen: false,
            hideMobileNav: false,
            showNavigationOverlay: false,
            // Language switcher
            languageSwitcherOpen: false,
            // Mobile navigation
            mobileLevelsOpen: [],
            // Newsletter modal
            showNewsletterModal: false,
            // CookieFirst
            cookieFirst: null,
            advertisingConsent: false,

            // Cart
            orderUploadsHasValue: false,

            // Product detail
            productDetailTextIsOpen: false
        },
        
        created() {
            window.addEventListener("resize", this.setMobileNavOffset);
            window.addEventListener("scroll", this.onWindowScroll);
        },
        
        mounted() {
            // Init AOS
            AOS.init({
                easing: "ease-in-out-quart",
                useClassNames: true,
                once: true
            });

            // resize iframes
            window.addEventListener('message', function(e) {
                var eventName = e.data[0];
                switch(eventName) {
                    case 'setIframeHeight':
                        var iframe = document.getElementById(e.data[1]);
                        if(iframe) {
                            var height = e.data[2];
                            iframe.height = height;
                        }
                        break;
                }
            }, false);
            
            // Lozad observer
            window.lozadObserver.observe();
            
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }
            
            // Set CSRF token
            const csrfToken = document.head.querySelector(
                'meta[name="csrf-token"]'
            );
            if (csrfToken) {
                window.axios.defaults.headers.common["X-CSRF-TOKEN"] =
                    csrfToken.content;
            } else {
                console.error("CSRF token not found.");
            }
            
            // Set siteId
            const siteId = document.head.querySelector('meta[name="siteId"]');
            if (siteId) {
                window.siteId = siteId.content;
            } else {
                console.error("siteId not found.");
            }
            
            // Set language
            window.rootUrl = "/";
            const language = document.head.querySelector(
                'meta[name="language"]'
            );
            if (language) {
                window.language = language.content;
                this.$i18n.locale = language.content;
                window.rootUrl = window.language !== "nl" ? "/en/" : "/";
            } else {
                console.error("language not found.");
            }
            
            // Using deprecated addListener() instead of addEventListener('change')
            // because Safari does not support addEventListener on MediaQueryList
            const breakpointNM = window.matchMedia("(min-width: 1024px)");
            breakpointNM.addListener(() => {
                this.isBreakpointNM = breakpointNM.matches;
            });
            // Set initial value
            this.isBreakpointNM = breakpointNM.matches;
            
            // Check if it's a touch device
            if ("ontouchstart" in window || navigator.MaxTouchPoints > 0) {
                this.isTouchDevice = true;
            }

            // Photoswipe lightbox
            this.initPhotoswipeLightbox();
            
            // Cookie First consents
            this.getCookieConsents();
            
            // Navigation UX
            this.setMobileNavOffset();
            
            // Call to action block
            this.initBlockCallToAction();
            
            // Text and media block (simple)
            this.initBlockTextAndMediaSimple();
            
            // Text and media block (vertical)
            this.initBlockTextAndMediaVertical();
            
            // Text and media block (dynamic)
            this.initBlockTextAndMedia();
            
            // Text and media block cols
            this.initBlockTextAndMediaCols();

            // Giftcards slider
            this.initBlockGiftCards();
            
            // Videos
            this.initVideos();
            
            // Load wishlist
            this.loadWishlist();
            
            // Update cart count to show in navigation
            this.$store.dispatch("updateCartCount");
            
            // Resize appointment iframes
            this.resizeAppointmentIframes();
        },
        
        beforeDestroy() {
            window.removeEventListener("resize", this.setMobileNavOffset);
            window.removeEventListener("scroll", this.onWindowScroll);
        },
        
        methods: {
            ...mapActions(["loadWishlist", "addToWishlist"]),
            
            async getCookieConsents() {
                // Will only update after a browser refresh
                while (!window.hasOwnProperty("CookieFirst")) await new Promise(resolve => setTimeout(resolve, 1000));
                
                // Set consents
                this.advertisingConsent = window.CookieFirst?.consent?.advertising || false;
            },
            
            // Resize you can book me iframes to correct height
            // Make sure you only have one appointment iframe as there is no way to
            // know what iframe is linked to which "message" event.
            // Therefore, we can not loop through every iframe and apply the correct height for each one.
            resizeAppointmentIframes() {
                window.addEventListener &&
                window.addEventListener(
                    "message",
                    (event) => {
                        if (event.origin.includes("youcanbook.me")) {
                            const iframe = document.querySelector(
                                ".block-iframe iframe"
                            );
                            iframe.style.height = event.data + "px";
                        }
                    },
                    false
                );
            },
            // Newsletter modal toggle
            openNewsletterModal() {
                this.showNewsletterModal = !this.showNewsletterModal;
            },
            
            // Check if any mobile levels are open
            isMobileLevelOpen: function (id) {
                return this.mobileLevelsOpen.includes(id);
            },
            
            // Open a specific mobile level (and navigate to page if needed)
            openMobileLevel(id, e) {
                if (!id) {
                    window.location = e.target.href;
                    this.closeMobileMenu();
                    return;
                }
                
                this.mobileLevelsOpen.push(id);
            },
            
            // Go to previous mobile level
            prevLevel() {
                this.mobileLevelsOpen.pop();
            },
            
            // Close mobile menu and check bodyLock
            closeMobileMenu() {
                this.mobileNavIsOpen = false;
                this.setBodyLock();
            },
            
            // Dropdown overlay
            showDropdownOverlay(e) {
                const item = e.currentTarget;
                const dropdownEl = item.querySelector(".dropdown-navigation");
                
                // Prevent events if the dropdown is not open or if there is no dropdown element available
                if (!item.classList.contains("active") && dropdownEl) {
                    e.preventDefault();
                }
                
                item.classList.add("active");
                
                if (dropdownEl) {
                    this.showNavigationOverlay = true;
                }
            },
            
            hideDropdownOverlay(e) {
                const item = e.currentTarget;
                item.classList.remove("active");
                
                this.showNavigationOverlay = false;
            },
            
            setMobileNavOffset() {
                // prettier-ignore
                this.mobileNavOffset = this.$refs.siteHeader ? this.$refs.siteHeader.clientHeight : 0;
            },
            
            onWindowScroll() {
                // Hide the site navigation on mobile when scrolling down and show it when scrolling up
                this.hideMobileNav =
                    !this.mobileNavIsOpen &&
                    window.pageYOffset > this.lastScrollPos &&
                    window.pageYOffset > this.mobileNavOffset;
                this.lastScrollPos = window.pageYOffset;
            },
            
            initBlockCallToAction() {
                // prettier-ignore
                const baseClass = 'block-call-to-action';
                const blockEl = document.querySelectorAll(`.${ baseClass }`);
                
                for (let block of blockEl) {
                    const buttons = block.querySelectorAll(".btn--hollow");
                    const overlay = block.querySelector(`.${ baseClass }__overlay`);
                    
                    for (let button of buttons) {
                        if (button && overlay) {
                            button.addEventListener("mouseover", () => {
                                overlay.classList.add(`${ baseClass }__overlay--active`);
                            });
                            
                            button.addEventListener("mouseout", () => {
                                overlay.classList.remove(`${ baseClass }__overlay--active`);
                            });
                        }
                    }
                }
            },
            
            initBlockTextAndMediaVertical() {
                // prettier-ignore
                const blockEl = document.querySelectorAll(".block-text-and-media-vertical");
                
                for (let block of blockEl) {
                    const blockSwiper = block.querySelector(
                        ".swiper-container"
                    );
                    const paginationEl = block.querySelector(
                        ".swiper-pagination"
                    );
                    
                    if (blockSwiper) {
                        const swiper = new Swiper(blockSwiper, {
                            loop: true,
                            autoHeight: true,
                            speed: 700,
                            effect: "fade",
                            fadeEffect: {
                                crossFade: true,
                            },
                            pagination: {
                                el: paginationEl,
                                clickable: true,
                            },
                        });
                        
                        for (let slide of swiper.slides) {
                            const video = slide.querySelector("video");
                            
                            // Meta loaded (width/height) rerender swiper;
                            // prettier-ignore
                            video && video.addEventListener("loadedmetadata", () => {
                                swiper.update();
                            });
                        }
                    }
                }
            },
            
            initBlockTextAndMedia() {
                // prettier-ignore
                const blockEl = document.querySelectorAll(".block-text-and-media");
                
                for (let block of blockEl) {
                    const blockSwiper = block.querySelector(
                        ".swiper-container"
                    );
                    
                    if (blockSwiper) {
                        const swiper = new Swiper(blockSwiper, {
                            loop: true,
                            autoHeight: true,
                            speed: 700,
                            effect: "fade",
                            fadeEffect: {
                                crossFade: true,
                            },
                            spaceBetween: 64, // content is extruded by p-8 * 2 = 32px * 2
                            pagination: {
                                el: ".swiper-pagination",
                                clickable: true,
                            },
                        });
                        
                        // console.log(swiper.slides);
                        
                        for (let slide of swiper.slides) {
                            const video = slide.querySelector("video");
                            
                            // Meta loaded (width/height) rerender swiper;
                            // prettier-ignore
                            video && video.addEventListener("loadedmetadata", () => {
                                swiper.update();
                            });
                        }
                    }
                }
            },
            
            initBlockTextAndMediaSimple() {
                // prettier-ignore
                const blockEl = document.querySelectorAll(".block-text-and-media-simple");
                
                for (let block of blockEl) {
                    const blockSwiper = block.querySelector(
                        ".swiper-container"
                    );
                    const paginationEl = block.querySelector(
                        ".swiper-pagination"
                    );
                    
                    if (blockSwiper) {
                        const swiper = new Swiper(blockSwiper, {
                            loop: true,
                            speed: 700,
                            effect: "fade",
                            fadeEffect: {
                                crossFade: true,
                            },
                            pagination: {
                                el: paginationEl,
                                clickable: true,
                            },
                        });
                        
                        // console.log(swiper.slides);
                        
                        for (let slide of swiper.slides) {
                            const video = slide.querySelector("video");
                            
                            // Meta loaded (width/height) rerender swiper;
                            // prettier-ignore
                            video && video.addEventListener("loadedmetadata", () => {
                                swiper.update();
                            });
                        }
                    }
                }
            },
            
            initBlockTextAndMediaCols() {
                const blockEl = document.querySelectorAll(
                    ".block-text-and-media-cols-slider"
                );
                
                for (let block of blockEl) {
                    const blockSwiper = block.querySelector(
                        ".swiper-container"
                    );
                    
                    if (blockSwiper) {
                        const swiper = new Swiper(blockSwiper, {
                            loop: true,
                            speed: 700,
                            slidesPerView: 1,
                            spaceBetween: 20,
                            centeredSlides: true,
                            pagination: {
                                el: ".swiper-pagination",
                                clickable: true,
                            },
                            breakpoints: {
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40
                                }
                            }
                        });
                    }
                }
            },

            initBlockGiftCards() {
                const blockEl = document.querySelectorAll(
                    ".block-giftcards"
                );

                for (let block of blockEl) {
                    const blockSwiper = block.querySelector(
                        ".swiper-container"
                    );

                    if (blockSwiper) {
                        const swiper = new Swiper(blockSwiper, {
                            loop: true,
                            speed: 700,
                            slidesPerView: 1,
                            spaceBetween: 20,
                            centeredSlides: true,
                            pagination: {
                                el: ".swiper-pagination",
                                clickable: true,
                            },
                            breakpoints: {
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 0
                                }
                            }
                        });
                    }
                }
            },

            initPhotoswipeLightbox() {
                const lightboxEls = document.querySelectorAll(".js-lightbox");
                for (let lightboxEl of lightboxEls) {
                    const lightbox = new PhotoSwipeLightbox({
                        gallery: lightboxEl,
                        children: "a",
                        showHideAnimationType: 'none',
                        showAnimationDuration: 0,
                        hideAnimationDuration: 0,
                        // optionally disable zoom transition,
                        // to create more consistent experience
                        zoomAnimationDuration: false,
                        pswpModule: () => import("photoswipe"),
                        padding: { top: 0, bottom: 0, left: 0, right: 0 }
                    });
                    lightbox.addFilter('thumbBounds', (thumbBounds, itemData, index) => {
                        return thumbBounds;
                    });
                    lightbox.on('uiRegister', function() {
                        console.log("uiRegister");
                        lightbox.pswp.ui.registerElement({
                            name: 'custom-caption',
                            order: 9,
                            isButton: false,
                            appendTo: 'root',
                            html: 'Caption',
                            onInit: (el, pswp) => {
                                lightbox.pswp.on('change', () => {
                                    const currSlideElement = lightbox.pswp.currSlide.data.element;
                                    let captionHTML = '';
                                    if (currSlideElement) {
                                        const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
                                        if (hiddenCaption) {
                                            // get caption from element with class hidden-caption-content
                                            captionHTML = hiddenCaption.innerHTML;
                                        } /*else {
                                            // get caption from alt attribute
                                            captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
                                        }*/
                                    }
                                    console.log(captionHTML);
                                    el.innerHTML = captionHTML || '';
                                });
                            }
                        });
                    });
                    lightbox.init();
                }
            },
            
            closeNotificationBar(slug) {
                VueCookies.set(`notification-${ slug }`, "hidden", "7d");
                this.showNotificationBar = false;
                Vue.nextTick(() => {
                    // On next tick change top offset
                    this.setMobileNavOffset();
                });
            },
            
            toggleMobileNav() {
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                this.setBodyLock();
            },
            
            setBodyLock() {
                // Enable/disable body scrolling
                this.mobileNavIsOpen && !this.isBreakpointNM
                ? disableBodyScroll(this.$refs.navMobile)
                : enableBodyScroll(this.$refs.navMobile);
            },
            
            goBack() {
                window.history.go(-1);
            },
            
            closeModal() {
                this.$store.dispatch("toggleModal");
            },
            
            toggleLanguageSwitcher() {
                this.languageSwitcherOpen = !this.languageSwitcherOpen;
            },
            
            closeLanguageSwitcher() {
                this.languageSwitcherOpen = false;
            },
            
            // Cart
            deleteLineItem(id) {
                document.getElementById("remove-" + id).value = 1;
                let data = new FormData(document.getElementById("form-cart"));
                
                axios
                    .post(".", data)
                    .then((result) => {
                        if (result.status === 200) {
                            location.reload();
                        } else {
                            this.$notify({
                                title: this.$t("product.generalError"),
                                text: this.$t("product.removeFromCartError"),
                                type: "error",
                            });
                            console.error("Cart error.", result);
                        }
                    })
                    .catch((error) => {
                        this.$notify({
                            title: this.$t("product.generalError"),
                            text: this.$t("product.removeFromCartError"),
                            type: "error",
                        });
                        console.error("Cart error.", error);
                    });
            },

            startCheckout(...args) {
                const form = document.getElementById("form-cart");
                if (form) {
                    const formData = new FormData(form);
                    axios({
                        url: '/actions/commerce/cart/update-cart',
                        method: 'POST',
                        data: formData
                    }).then(result => {
                        if (result.status === 200) {
                            //this.fbPixelInitiateCheckout(...args);

                            window.location = window.location.origin + "/checkout";
                        } else {
                            this.$notify({
                                title: i18n.$t('product.generalError'),
                                text: i18n.t('product.tryAgain'),
                                type: 'error'
                            });
                            console.error('Error updating cart.', result);
                        }
                    }).catch(error => {
                        this.$notify({
                            title: i18n.t('product.generalError'),
                            text: i18n.t('product.tryAgain'),
                            type: 'error'
                        });
                        console.error('Error updating cart.', error);
                    });
                }
            },
            
            // Facebook Pixel event
            fbPixelInitiateCheckout(totalValue, qty, skus) {
                window.fbq("track", "InitiateCheckout", {
                    content_ids: skus,
                    value: totalValue,
                    num_items: qty,
                    currency: "EUR",
                });
            },
            
            initVideos() {
                const blockEl = document.querySelectorAll(".js-video");
                const videosOnPage = [];
                
                for (let block of blockEl) {
                    const videoEl = block.querySelector("video");
                    videosOnPage.push(videoEl)
                    const playBtnEl = block.querySelector('.btn-play');
                    
                    // Remove controls by default
                    videoEl.removeAttribute("controls");
    
                    // Add play event listener
                    playBtnEl.addEventListener("click", (e) => {
                        e.preventDefault();
                        
                        // Pause all videos on page first so there is only one playing
                        videosOnPage.forEach(vid => vid.pause());
                        
                        // Play current video
                        videoEl.play();
                    });
                    
                    // Set video is playing state
                    videoEl.addEventListener("play", () => {
                        // Keeps track if the video has been played before or not (e.g. to hide the "play" icon after being initially played)
                        block.classList.add("has-played");
                        // Class added to the parent block so we know a video is currently playing
                        block.classList.add("playing");
                        // Add our default video controls
                        videoEl.setAttribute("controls", true);
                    });
                    
                    // Remove video is playing state
                    videoEl.addEventListener("pause", () => {
                        // Display that the video is not playing anymore
                        block.classList.remove("playing");
                        videoEl.removeAttribute("controls");
                    });
                }
                
            },

            onOrderUploadsChange() {
                const field = document.getElementById("orderUploads");
                this.orderUploadsHasValue = !!field.files.length;
            },

            toggleProductDetailText() {
                this.productDetailTextIsOpen = !this.productDetailTextIsOpen;
            }
        },
        
        computed: {
            ...mapGetters(["getWishlistCount", "isOnWishlist", "showModal"])
        },
        watch: {
            isBreakpointNM: function () {
                // Whenever we resize our window with the navigation open we want to lock/unlock our navigation based on screenregion
                this.setBodyLock();
            }
        }
    });
    
    return vm;
};

// Execute async function
main().then((vm) => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
